#featureProducts-section {
  h3[class*="headH3"] {
    margin-bottom: 1.5rem !important;
  }

  div[class*="uSBCardCol"] {
    min-height: unset;

    a.usb-link {
      &:hover {
        text-decoration: none !important;
      }
    }
  }
}
